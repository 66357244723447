<template>
  <div class="ad--container">
    <Adsense
      data-ad-client="ca-pub-7702415526221680"
      data-ad-slot="8750381466"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></Adsense>
  </div>
</template>

<script>
export default {
  name: "Ad"
};
</script>
